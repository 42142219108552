(function($) {

  rec_widget = {
    name : '',
    email : '',
    agentIntroduced : false,
    emailSent : false,

    notifyWhenDone : function (err) {
      if (err) {}
    },
    onSuccess : function (data) {
      console.log(data);
    },
    initChat : function (data) {
      // check to see if agent intro has already been sent
      for(var i = 0; i < data.length; i++){
        if (data[i].text.indexOf('consultant_pic') > 0) {
          rec_widget.agentIntroduced = true;
        }
        if (data[i].text.indexOf('js-hook_email') > 0) {
          rec_widget.emailSent = true;
        }
      }
      if (!rec_widget.agentIntroduced) {
        lpTag.agentSDK.get('agentInfo',	rec_widget.introAgent,	rec_widget.onError);
      }
      if (!rec_widget.emailSent) {
        lpTag.agentSDK.get('surveyQuestions',	rec_widget.setEmail,	rec_widget.onError);
      }
    },
    introAgent : function (data) {
      // make call for agent data if we have an email address...
      if (rec_widget.email !== '') {
        generic.jsonrpc.fetch({
          method: 'liveperson.agent',
          params: [{
            "REP_ID": data.agentId
          }],
          onSuccess: function(jsonRpcResponse) {
            var agentObj = jsonRpcResponse.getValue();

            if(!agentObj) { // nothing in our DB - default to LP profile
              var nickname = data.agentNickname;
              lpTag.agentSDK.command(lpTag.agentSDK.cmdNames.write, {text: '<h2 class="js-hook_email_'+rec_widget.email+'_end consultant_pic">' + nickname + '</h2>' }, rec_widget.notifyWhenDone);
            } else {
              var nickname = (agentObj.NICKNAME).toUpperCase();
              var consultant_yrs_msg = !!agentObj.YEARS_WITH_ELC ?  '<p>'+agentObj.YEARS_WITH_ELC+'</p>' : '';
              var fav_tip = !!agentObj.FAVORITE_TIP ?  '<p>'+agentObj.FAVORITE_TIP+'</p>'   : '';
              var fav_product = !!agentObj.FAVORITE_PRODUCTS ? '<p>'+agentObj.FAVORITE_PRODUCTS+'</p>'  : '';
              lpTag.agentSDK.command(lpTag.agentSDK.cmdNames.write, {text: '<center><img class="js-hook_consultant_pic" src="'+agentObj.IMAGE_PATH+'" style="width: 100%"></center>'
                  + '<h2 class="js-hook_email_'+rec_widget.email+'_end">' + nickname + '</h2>' + consultant_yrs_msg + fav_tip + fav_product }, rec_widget.notifyWhenDone);
            }
          },
          onFailure: function (jsonRpcResponse) {
            console.log('error getting agent info: ', jsonRpcResponse.getValue());
          }
        });
      }
    },
    setEmail : function (data) {
      rec_widget.email = data.preChat.email.value;
    },
    onError : function (err) {
      if (err) {}
    },
    getUrlParameter : function(sParam) {
      var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;
      for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
          return sParameterName[1] === undefined ? true : sParameterName[1];
        }
      }
    }

  };

  // only call if lp_account param detected and matched.
  var is_agent_widget = ((typeof(window.LP_ACCOUNT_ID) == 'number') // as set in .inc and posted by clinique_chat_widget.module
                          && (typeof(rec_widget.getUrlParameter('lp_account')) !== 'undefined') // as added by LP or keepLinksInternal below
                          && (window.LP_ACCOUNT_ID === Number(rec_widget.getUrlParameter('lp_account')))); // they should match
  if (is_agent_widget) {
    // init lpTag calls
    lpTag.agentSDK.init({notificationCallback: rec_widget.notifyWhenDone});
    lpTag.agentSDK.get('surveyQuestions',	rec_widget.setEmail,	rec_widget.onError);
    lpTag.agentSDK.get('chatTranscript.lines',	rec_widget.initChat,	rec_widget.onError);
    // these calls just to see what we have to work with...
    lpTag.agentSDK.get('visitorInfo',	rec_widget.onSuccess,	rec_widget.onError);
    lpTag.agentSDK.get('agentInfo',	rec_widget.onSuccess,	rec_widget.onError);
    lpTag.agentSDK.get('chatInfo',	rec_widget.onSuccess,	rec_widget.onError);
    lpTag.agentSDK.get('surveyQuestions',	rec_widget.onSuccess,	rec_widget.onError);

    // update text of add-to-cart buttons to read 'recommend', or whatever is passed as a setting from LP
    var recommend_txt = rec_widget.getUrlParameter('rec_btn_txt') || 'recommend';
    $('.btn-add-to-bag, .add-to-cart').html(recommend_txt);

    // keep links referencing theme with lp acount_id
    var keepLinksInternal = function () {
      $('a[href*="/product"]').each(function(i, alink){
        var href = $(alink).attr('href');
        if (href.indexOf('' + window.LP_ACCOUNT_ID) === -1) {
          var nlink = href + '?lp_account=' + window.LP_ACCOUNT_ID + '&rec_btn_txt=' + recommend_txt;
          $(alink).attr('href', nlink);
        }
      });
      $('#perlgem-search-form').each(function(i, alink){
        var action = $(alink).attr('action');
        if (action.indexOf('' + window.LP_ACCOUNT_ID) === -1) {
          var nlink = action + '?lp_account=' + window.LP_ACCOUNT_ID + '&rec_btn_txt=' + recommend_txt;
          $(alink).attr('action', nlink);
        }
      });
    };
    setInterval(keepLinksInternal, 500);

    // override add-to-bag - notify chat window instead
    site.addToCart = function(args) {
      var skuBaseId;
      if (args.skuData && args.skuData.SKU_BASE_ID) {
          skuBaseId = args.skuData.SKU_BASE_ID;
      } else if (args.skuBaseId) {
          skuBaseId = args.skuBaseId;
      } else {
          return null;
      }
      generic.jsonrpc.fetch({
        method: 'prodcat',
        params: [{
            "query_key":"catalog-spp",
            "skus":['SKU'+skuBaseId],
            "product_fields": ['PRODUCT_ID','PROD_RGN_NAME'],
            "sku_fields": ['SKU_ID','SHADENAME','INVENTORY_STATUS','DISPLAY_STATUS','SKU_BASE_ID','isOrderable','isShoppable','product']
          }],
        onSuccess: function(jsonRpcResponse) {
          var response = jsonRpcResponse.getValue();
          var product_id = response.skus[0].product.PRODUCT_ID;
          var product_name = response.skus[0].product.PROD_RGN_NAME;
          var sku_label = (response.skus[0].SHADENAME !== null) ? response.skus[0].SHADENAME : '';
          lpTag.agentSDK.command(lpTag.agentSDK.cmdNames.write, {text: '<strong class="js-hook_product_rec_' + product_id + 'SKU' + skuBaseId + '_end">' + product_name + ' ' + sku_label + '</strong>'}, rec_widget.notifyWhenDone);
          // manually reset rec btn since site.loading fails to do it
          setTimeout(function(){
            $('.loading-status').remove();
            $('.add-to-cart[data-skubaseid='+skuBaseId+']').show();
          }, 2000);
        },
        onFailure: function (jsonRpcResponse) {}
      });
    }

    // add 'lp_agent_widget' classname to body
    $('body').addClass('lp_agent_widget');
  }

})(jQuery);